<template>
  <div class="page page-project-apply">
    <div class="fit v-pa-md v-mx-auto" style="max-width: 1000px">
      <template v-if="!applied">
        <h2 class="text-center text-primary">项目申报</h2>
        <!-- <a-divider /> -->
        <div class="v-pa-lg v-my-lg">
          <a-steps progress-dot :current="currentStep" size="small">
            <a-step title="提交项目意向" />
            <a-step title="千禾评审" />
            <a-step title="提交项目申请书" />
            <a-step title="签订资助协议" />
            <a-step title="项目资助" />
            <a-step title="项目结项" />
            <a-step title="完成" />
          </a-steps>
        </div>
        <div class="panel v-mx-auto" style="max-width: 1000px">
          <div class="panel-body">
            <a-alert class="v-mb-sm" type="error" v-if="data.reviewResult === 0">
              <div slot="message" class="text-red">上次项目意向申请已被驳回，审核意见：{{ data.reviewOpinion }}</div>
            </a-alert>
            <a-form-model ref="applyForm" :model="applyForm" :label-col="{ span: 5 }" :wrapper-col="{ span: 17 }">
              <a-form-model-item :colon="false" prop="subject">
                <span slot="label">
                  <i class="required-mark">*</i>
                  项目板块
                </span>
                <a-select placeholder="选择项目板块" v-model="applyForm.subject" style="width: 200px" allowClear ref="subject" @change="handleSubjectChange">
                  <a-select-option v-for="item in subjects" :key="item.value === void 0 ? item.label : item.value">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item :colon="false" prop="topic">
                <span slot="label">
                  <i class="required-mark">*</i>
                  项目议题
                </span>
                <a-select placeholder="选择项目议题" v-model="applyForm.topic" style="width: 300px" allowClear ref="topic">
                  <a-select-option v-for="item in topics" :key="item.topicName">
                    {{ item.topicName }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item :colon="false" prop="summary">
                <span slot="label">
                  <i class="required-mark">*</i>
                  项目意向概述
                </span>
                <a-textarea v-model="applyForm.summary" placeholder="项目意向概述" :rows="6" style="width: 100%" ref="summary" />
              </a-form-model-item>
              <a-form-model-item :colon="false" prop="userType">
                <span slot="label">
                  <i class="required-mark">*</i>
                  文件上传
                </span>
                <a-upload-dragger
                  :action="`${baseURL}/file/upload`"
                  :data="{
                    params: JSON.stringify({ fileMark, fileBelongId: id, fileType: '201', fileSign: 'P01' }),
                  }"
                  :show-upload-list="{
                    showPreviewIcon: true,
                    showRemoveIcon: true,
                    showDownloadIcon: true,
                  }"
                  :file-list="fileList"
                  :before-upload="beforeUploadFile"
                  @preview="handlePreviewFile"
                  @change="handleUploadChange"
                  @download="({ fileId, name }) => downloadFile({ fileId, belongId: id, fileSign: 'P01', fileName: name })"
                  accept=".png, .jpg, .jpeg, .pdf, .doc, .docx, .pdf, .rar, .zip, .ppt, .pptx, .xls, .xlsx"
                >
                  <div class="v-pa-xs">
                    <a-icon type="plus" />
                    <p class="font-xs">上传项目意向书等文件</p>
                  </div>
                </a-upload-dragger>
              </a-form-model-item>
              <a-form-model-item :colon="false" prop="userType">
                <span slot="label"></span>
                <a-space>
                  <a-button type="default" icon="check" @click="onSubmit(false)" :loading="saving">保存初稿</a-button>
                  <a-button type="primary" icon="arrow-right" @click="onSubmit(true)" :loading="saving">提交至千禾审核</a-button>
                  <a-button type="default" icon="rollback" @click="$router.go(-1)">返回</a-button>
                </a-space>
              </a-form-model-item>
            </a-form-model>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="bg-white" style="width: 600px; margin: 0 auto">
          <a-result status="success" title="项目意向提交成功" :sub-title="`请等待千禾审核，审核结果将通过短信发至您的手机${userinfo.phone}`">
            <template #extra>
              <a-button key="index" @click="$router.push('list?tab=unstarted')">返回项目列表</a-button>
            </template>
          </a-result>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { applyProject, getProject, listTopic } from '@/api/main'
export default {
  name: 'ProjectApply',
  data() {
    return {
      id: this.$route.query.id,
      currentStep: 0,
      fileMark: this.$utils.uuid(),
      applied: false,
      applyForm: {
        id: null,
        subject: undefined,
        topic: undefined,
        summary: '',
      },
      data: {},
      topics: [],
      fileList: [],
      saving: false,
    }
  },
  computed: {
    subjects: vm => vm.$store.state.app.subjects,
  },
  methods: {
    load() {
      let id = this.$route.query.id
      getProject(id).then(({ data }) => {
        if (data.success) {
          let { id, subject, topic, summary, files } = data.result
          this.fileList = files
            .filter(file => file.fileType === '201')
            .map(file => ({
              uid: file.id,
              fileId: file.id,
              name: file.fileOriginName,
              ...file,
              status: 'done',
              fileSign: 'P01',
              belongId: id,
            }))
          this.applyForm = {
            id,
            subject,
            topic,
            summary,
          }
          this.data = data.result
        }
      })
    },
    handleUploadChange(info) {
      this.fileList = this.handleUploadFile(info, -1)
    },
    handleSubjectChange(subject) {
      let params = {
        subject,
        visible: 1,
        pageSize: -1,
      }
      listTopic(params).then(({ data }) => {
        if (data.success) {
          this.applyForm.topic = undefined
          this.topics = data.result.records
        }
      })
    },
    isModified() {
      let form = this.applyForm
      let data = this.data
      if (form.id === data.id && form.subject === data.subject && form.topic === data.topic && form.summary.trim() === data.summary.trim()) {
        let originFileIds = data.files.map(file => file.id)
        let fileIds = this.fileList.map(file => file.fileId)
        if (originFileIds.length != fileIds.length) {
          return true
        }
        if (fileIds.some(id => !originFileIds.includes(id))) {
          return true
        }
        return false
      }
      return true
    },
    handleSubmit(nextStep) {
      let { id, subject, topic, summary } = this.applyForm
      let { fileList, fileMark } = this
      // fileList = fileList.filter(file => file.fileId)
      let fileIds = fileList.filter(file => file.fileId).map(file => file.fileId)
      let params = {
        id,
        subject,
        topic,
        summary: summary.trim(),
        fileMark,
        nextStep,
        fileIds,
      }
      this.saving = true
      applyProject(params)
        .then(({ data }) => {
          if (data.success) {
            this.$message.success('保存成功')
            if (nextStep) {
              this.applied = true
            } else {
              this.$router.push('list?tab=unstarted')
            }
          } else {
            this.$message.warning(data.msg)
          }
          this.saving = false
        })
        .catch(err => {
          if (err) {
            this.$message.warning(err)
          }
          this.saving = false
        })
    },
    onSubmit(nextStep) {
      let vm = this
      let { id, subject, topic, summary } = this.applyForm
      // let { fileList, fileMark } = this
      let fileList = this.fileList.filter(file => file.fileId)
      this.$message.destroy()
      if (!subject) {
        this.$refs.subject.focus()
        this.$message.warning('请选择项目板块')
        return
      }
      if (!topic) {
        this.$refs.topic.focus()
        this.$message.warning('请选择项目议题')
        return
      }

      if (summary.trim() === '') {
        this.$refs.summary.focus()
        this.$message.warning('请输入项目意向概述')
        return
      }
      if (fileList.length === 0) {
        this.$message.warning('请上传相关文件')
        return
      }
      if (id && !this.isModified()) {
        this.$confirm({
          icon: 'info-circle',
          title: '您未进行任何改动，是否确认提交？',
          okText: '提交',
          cancelText: '关闭',
          // centered: true,
          onOk() {
            vm.handleSubmit(nextStep)
          },
        })
      } else {
        this.handleSubmit(nextStep)
      }
    },
  },
  created() {
    if (this.$route.query.id) {
      this.load()
    }
  },
}
</script>
